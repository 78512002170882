import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-5 sm:mx-auto sm:w-full content-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "w-screen h-screen object-fill object-center bg-cover fixed -z-1 top-0 left-0",
  xmlns: "http://www.w3.org/2000/svg",
  style: {"background-color":"#fff"},
  fill: "#e5e5e5",
  stroke: "#000",
  "stroke-width": "1"
}
const _hoisted_5 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "h-16 text-center w-full mb-5 object-contain",
        src: require('@/assets/images/SJ-logo.png'),
        alt: "t-recs logo"
      }, null, 8, _hoisted_3),
      _createVNode($setup["RedeemRegistrationFormVue"])
    ]),
    (_openBlock(), _createElementBlock("svg", _hoisted_4, [
      _createElementVNode("use", {
        "xlink:href": $setup.SVGWorldMap,
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }, null, 8, _hoisted_5)
    ]))
  ]))
}