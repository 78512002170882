export const privacyPolicy = `This Privacy Policy governs the manner in which T-RECs.ai collects, uses, maintains and discloses information collected from users (each, a "User") of the <a href="www.trecs.ai"> www.trecs.ai website ("Site")</a>. This privacy policy applies to the Site and all products and services offered by T-RECs.ai

Personal identification information

We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.

Non-personal identification information

We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.

Web browser cookies

Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.

How we use collected information

T-RECs.ai may collect and use Users personal information for the following purposes:
To process payments. We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.
To run a promotion, contest, survey or other Site feature
To send Users information they agreed to receive about topics we think will be of interest to them.
To send periodic emails
We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. User will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email, or User can choose to send unsubscribe request to enquiry@trecs.ai

How we protect your information

We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.

Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.
Sharing your personal information

We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.

Changes to this privacy policy

T-RECs.ai has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.

Your acceptance of these terms

By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.

Contacting us
If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:
www.trecs.ai`;

export const KycAml = `The Policy on Prevention of Money Laundering and Terrorist Financing (hereinafter — “the AML Policy”) outlines the minimum general unified standards of sanctions, anti-money laundering and combating terrorism financing which should be adhered to by T-RECs.ai Pte Ltd.

The AML Policy is designed to ensure that we comply with the requirements and obligations set out in the applicable Singapore legislation, regulations, rules and Industry Guidance for the financial services sector, including the need to have adequate systems and controls in place to mitigate the risk of the company being used to facilitate financial crime.

In any country where the applicable anti-money laundering laws and regulations require us to establish higher standards, they must meet those standards. Adherence to this Policy is absolutely essential for ensuring that we, regardless of geographic location, will seek to fully comply with applicable anti-money laundering laws and regulations.

These policies and principles are designed to ensure that all Group Companies comply with the legal and regulatory requirements applicable in the Singapore as well as with their local obligations.

The AML Policy
The AML Policy sets out the minimum standards which must be complied with by us includes:

The appointment of a Money Laundering Reporting Officer (MLRO), who have responsibility for oversight of our compliance with relevant legislation, regulations, rules and industry guidance;
Establishing and maintaining a Risk Based Approach (RBA) towards assessing and managing the money laundering and terrorist financing risks;
Establishing and maintaining risk-based customer due diligence, identification, verification and know your customer (KYC) procedures, including enhanced due diligence for those customers presenting higher risk, such as Politically Exposed Persons (PEPs) and Correspondent relationships;
Establishing and maintaining risk based systems and procedures to monitor ongoing user activity;
Procedures for reporting suspicious activity internally and to the relevant law enforcement authorities as appropriate;
The maintenance of appropriate records for the minimum prescribed periods;
Training and awareness for all relevant employees in our Company; and
The provision of appropriate management information and reporting to senior management of the Company’s compliance with the requirements.
Sanctions Policy:
The Company is prohibited from transacting with individuals, companies and countries that are on prescribed Sanctions lists. We will therefore endeavor to screen against United Nations, European Union, UK Treasury and US Office of Foreign Assets Control (OFAC) sanctions lists in all jurisdictions in which we operate.

List of Prohibit Individual, Uses and Transactions
Fraud, gambling, high risk business, money laundering, terrorist financing, ransomware, darknet market, scam, hack/stolen funds, high risk exchanges, abusive, drugs, adult material and multi level marketing, sanctioned countries/individuals and political exposed individual.

ANTI-CORRUPTION STATEMENT
The Company is fully committed to ensuring that all its employees, and the Company as an entity, comply with all anti-bribery and corruption laws in all jurisdictions where the Company operates.

The Company has zero tolerance for bribery and corruption.

Definitions
Corrupt behaviour is defined as dishonestly acting, or dishonestly failing to act, in the performance of functions of one’s employment, or dishonestly taking advantage of one’s employment to obtain benefit for himself or herself, the Company or for another person or organisation, or to cause loss to another party / person.

Bribery is defined as the act of

Providing, offering or causing a benefit to another person with the intention of influencing for a business or personal advantage, where the benefit is not legitimately due and regardless of whether the recipient is the intended target of the benefit;
Soliciting or receiving a benefit from another person with the intention of providing a business or personal advantage, where the benefit is not legitimately due; or
Inducing or permitting a third party to provide, offer or cause a benefit to another person with the intention of influencing for a business or personal advantage, where the benefit is not legitimately due regardless of whether the recipient is the intended target of the benefit.
In determining whether corruption or bribery has occurred, the benefit received may be monetary or otherwise. Bribery can involve the giving, receiving or acceptance of any gift, reward, hospitality or other offer which could be perceived as an incentive, favour or inducement to perform an improper act or gain a business or personal advantage.

Principles
The Company will uphold the following standards relating to all matters concerning anti-bribery or anti-corruption.

The Company strictly prohibits all forms of bribery and corruption, including facilitation payments. The Company will report all instances of bribery and corruption to the relevant authorities without exception.
When receiving or giving gifts and/or providing entertainment, employees must ensure that that gifts and/or entertainment are reasonable and proportionate to the purpose for which they are given or taken.
All employees shall report any issues of conflict of interest before giving or receiving gifts.
All questionable behaviour should be reported to the relevant reporting officer(s), and all suspicions of impropriety relating to corruption or bribery should be reported to management directly or via the Company’s whistleblowing channels.
Employees should exercise heightened care and diligence when interacting with public officials.
KYC Process
In accordance with strict financial rules and regulations, T-RECs.ai collects and verifies the personal identification data of all our clients account holders. In order to open an active trading account, you must go through identification procedure. The ID you provide must comprise of a primary form of Photo ID which includes a valid government issued document showing your full name and date of birth, residential address. Our procedures ensure the safe trading for our customers.

We collect a wide range of personal information through the Services. This information varies depending on the verification proceeding during the application, but may include such information as: name, physical address, email address, telephone number, identification number, driver’s license number, passport number, other ID card number, credit or debit card number, CVV, expiration date, and/or date of birth.

We may also collect a visually scanned or photographed image of your face and/or your identification card, driver’s license, passport, utility bill, bank account statement, insurance card, or credit/debit card.

In the matter of companies, procedure is more strict and depend on the company structure, country etc. Each company will be verified in the individual manual procedure, on the base of documents including act of incorporation, legal representation, personal verification of board members etc. Each case of verification is individual. All proof of residences/ seat must be current, show the full document, clearly display the name and address (no PO Boxes).

Contacting us
If you have any questions about this Terms of Use, the practices of this site, or your dealings with this site, please contact us at:`;

export const TermsConditions = `Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the http://www.trecs.ai website (the "Service") operated by T-RECs.ai Pte Ltd ("us", "we", or "our").

Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.

By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.

Accounts

When you create an account with us, you must undertake and agree to provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service at our sole discretion.

You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.

You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. The use of your account with us is premised on you as the sole user and not transferable to any other party. Any unauthorised use will constitute a breach of these Terms and liable to immediate termination.

Links To Other Web Sites
Our Service may contain links to third-party websites or services that are not owned or controlled by T-RECs.ai.

T-RECs.ai has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You do hereby further acknowledge and agree that T-RECs.ai shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.

We strongly advise you to read all the terms and conditions and privacy policies of any third-party web sites or services that you visit.

Termination
We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach any of the All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

Governing Law

These Terms shall be governed and construed in accordance with the laws of Singapore, without regard to its conflict of law provisions.

Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.

These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.

Dispute Resolution

If any dispute shall arise in connection with these Terms, either you or we may initiate the dispute resolution procedures set forth in this section by giving the other party written note of such dispute ( “Dispute Notice” ).

Following issuance of a Dispute Notice, both you and we shall agree to participate in mediation and undertake to abide by the terms of any settlement reached. The mediation must take place within sixty (60) days of the date of the Dispute Notice, and the mediator shall be an Associate or Principal Mediator accredited by the Singapore Mediation Centre or an accredited mediator who has been agreed on an ad hoc basis between the parties for the purposes of seeking a possible resolution to the dispute. Parties shall endeavour to agree on a mediator, failing which the mediator shall be selected by the Singapore Mediation Centre.
If the dispute cannot be resolved by mediation within sixty (60) days of the date of the Dispute Notice, then either you or we may refer the dispute to arbitration administered by the Singapore International Arbitration Centre (SIAC) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (SIAC Rules) for the time being in force, which rules are deemed to be incorporated by reference to this section. The seat of the arbitration shall be Singapore. The language of the arbitration shall be English.
Changes
We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. However, the absence of notice should not affect the validity of any new terms whatsoever. What constitutes a material change will be determined at our sole discretion.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.

Contacting us
If you have any questions about this Terms of Use, the practices of this site, or your dealings with this site, please contact us at:
www.trecs.ai
71 Ayer Rajah Crescent
#6-15
Singapore 139951
enquiry@trecs.ai`;
